import React, { Component } from "react";
import Finalists, { FinalistCarouselItemRenderer } from "slices/Finalists";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DialogCarousel from "components/DialogCarousel";
import Footer from "components/Footer";
import Dialog from "react-a11y-dialog";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Helmet from "react-helmet";
import AboutAwardBlock from "slices/AboutAwardBlock";
import BigCalloutLinks from "slices/BigCalloutLinks";
import ContentColumns from "slices/ContentColumns";
import FinalistLongList from "slices/FinalistLongList";
import FullWidthCallouts from "slices/FullWidthCallouts";
import Gallery from "slices/Gallery";
import Gallery2024 from "slices/Gallery2024";
import HTMLContent from "slices/HTMLContent";
import GlobalEmitter from "utils/GlobalEmitter";
// Slices
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import JotFormEmbed from "components/JotFormEmbed";
import Route from "route-parser";
import Hero from "slices/Hero";
import IFrameEmbed from "slices/IFrameEmbed";
import Jury from "slices/Jury";
import Quote from "slices/Quote";
import QuoteGallery from "slices/QuoteGallery";
import Sponsor from "slices/Sponsor";
import Winner from "slices/Winner";
import WritersBooksCarousel from "slices/WritersBooksCarousel";
import { firstBy } from "thenby";
import events from "utils/events";
import { getOgImageFromGallery } from "utils/getOgImageFromGallery";
// graphql
import { graphql, navigate } from "gatsby";
import querystring from "querystring";
import heroStyles from "slices/Hero/Hero.module.scss";
import { renderText } from "utils/renderHelpers";
import resolveLinkById from "utils/resolveLinkById";
import { sliceTypeMatches } from "utils/slices";
import uid from "utils/uid";

let suppressDialog = false;

const setSuppressDialog = (bool) => {
  suppressDialog = bool === true;
};

const stripFormTags = (html) => {
  let result = html.replace(/style="[^"]*"/g, "");
  return result;
};

const getFinalistsDialogContent = (
  stateObj,
  baseSlug,
  url,
  finalists,
  title,
  year,
  awardType,
  forceAuthor = false
) => {
  if (!finalists) {
    return null;
  }
  finalists.sort((a, b) => {
    const aname = a.sort_title;
    const bname = b.sort_title;
    if (aname > bname) {
      return 1;
    }
    if (bname > aname) {
      return -1;
    }
    return 0;
  });
  stateObj.dialogContents = (
    <DialogCarousel
      items={finalists.map((finalist, idx) => {
        const isAuthor =
          !finalist.finalistData ||
          !finalist.finalistData.book ||
          finalist.finalistData.forceAuthor;
        return (
          <FinalistCarouselItemRenderer
            key={`finalist-${idx}`}
            isAuthor={isAuthor}
            finalist={finalist}
            title={title}
            year={year}
            awardType={awardType}
            showOneOnly={finalist.finalistData.forceAuthor}
            setSuppressDialog={setSuppressDialog}
          />
        );
      })}
      urls={finalists.map((node) => {
        return node.uid;
      })}
      url={url}
      baseUrl={baseSlug}
    />
  );
  return stateObj;
};

class GuidelinesDialogContent extends Component {
  render() {
    const {
      guidelines_title,
      guidelines_subtitle,
      guidelines_top_paragraph,
      form_embed,
      source_code,
      pdf_application,
      external_url,
      body1,
    } = this.props.guidelinesProps;
    // console.log(this.props.guidelinesProps)

    let hasFormEmbed = form_embed && form_embed.embed_url;
    let isJotform =
      hasFormEmbed && form_embed.provider_name.toLowerCase() === "jotform";

    let hasFormSourceCode = source_code && source_code.text;
    let hasPdf = pdf_application && pdf_application.url;

    let hasExternalLink =
      external_url && external_url.text && external_url.text.length > 0;

    let isDefinitelyEmbed = !hasExternalLink && !hasPdf;

    // console.log({ hasFormEmbed, isJotform });

    return (
      <React.Fragment>
        <Container>
          <Columns mobile multiline centered>
            <Columns.Column mobile={{ size: 11 }} tablet={{ size: 10 }}>
              <header>
                {renderText(guidelines_title, `h1`)}
                {renderText(
                  guidelines_subtitle,
                  `h4`,
                  `has-text-red has-font-weight-bold`
                )}
              </header>
            </Columns.Column>
          </Columns>
          <Columns mobile multiline centered>
            <Columns.Column
              mobile={{ size: 11 }}
              tablet={{
                size: hasFormEmbed || hasExternalLink || hasPdf ? 5 : 10,
              }}
            >
              <HTMLContent html={guidelines_top_paragraph} columns={1} />
            </Columns.Column>
            {hasPdf && (
              <Columns.Column
                style={{ textAlign: `center` }}
                mobile={{ size: 11 }}
                tablet={{ size: 5 }}
              >
                <a
                  rel="noopener noreferrer"
                  className="anchor"
                  href={pdf_application.url}
                  target="_blank"
                  title="Download Application Form"
                >
                  <span>Application Form</span>
                </a>
              </Columns.Column>
            )}
            {hasExternalLink && (
              <Columns.Column
                mobile={{ size: 11 }}
                tablet={{ size: 5 }}
                style={{ textAlign: `center` }}
              >
                <a
                  rel="noopener noreferrer"
                  className="anchor"
                  href={external_url.text}
                  target="_blank"
                  title="Go to Application Form"
                >
                  <span>Application Form</span>
                </a>
              </Columns.Column>
            )}
            {isDefinitelyEmbed && (
              <React.Fragment>
                {(hasFormSourceCode || (hasFormEmbed && isJotform)) && (
                  <Columns.Column
                    mobile={{ size: 11 }}
                    tablet={{ size: 5 }}
                    style={{ textAlign: `center` }}
                  >
                    <a
                      className="anchor"
                      href="#form-embed"
                      title="Jump to submission form"
                    >
                      <span>Submission form</span>
                      <i className="icon">
                        <FontAwesomeIcon icon={faCaretDown} />
                      </i>
                    </a>
                  </Columns.Column>
                )}
                {hasFormEmbed && !isJotform && (
                  <Columns.Column
                    mobile={{ size: 11 }}
                    tablet={{ size: 5 }}
                    style={{ textAlign: `center` }}
                  >
                    <a
                      rel="noopener noreferrer"
                      className="anchor"
                      href={form_embed.embed_url}
                      target="_blank"
                      title="Goto to submission form on Submittable"
                    >
                      <span>Submission form</span>
                    </a>
                  </Columns.Column>
                )}
              </React.Fragment>
            )}
            {body1 &&
              body1.length &&
              body1.map(({ primary, items, slice_type, id }, idx) => {
                return (
                  <Columns.Column
                    mobile={{ size: 11 }}
                    tablet={{ size: 10 }}
                    key={`body-${id}`}
                  >
                    <HTMLContent columns={1} html={primary.heading} />
                    {items.map((item, idx2) => (
                      <HTMLContent
                        key={`body-items-${id}-${idx}-${idx2}`}
                        html={item.content}
                        columns={parseInt(primary.columns)}
                      />
                    ))}
                  </Columns.Column>
                );
              })}
          </Columns>
          {hasFormSourceCode ? (
            <Columns mobile centered>
              <Columns.Column mobile={{ size: 11 }} tablet={{ size: 10 }}>
                <article
                  id="form-embed"
                  className="form-embed"
                  dangerouslySetInnerHTML={{
                    __html: stripFormTags(source_code.text),
                  }}
                />
              </Columns.Column>
            </Columns>
          ) : hasFormEmbed && isJotform ? (
            <Columns mobile centered>
              <Columns.Column
                mobile={{ size: 11 }}
                tablet={{ size: 10 }}
                id="form-embed"
              >
                <JotFormEmbed src={form_embed.embed_url} />
              </Columns.Column>
            </Columns>
          ) : null}
        </Container>
      </React.Fragment>
    );
  }
}

const parseFinalists = (finalists, year, uid, awardType = "single") => {
  let result = finalists.edges.map(({ node, slug }) => ({
    slug,
    ...node,
  }));
  result.forEach((f) => {
    f.finalistData = f.data.award_finalist.find((item) => {
      return (
        item.year &&
        item.year.trim() === year.trim() &&
        item.award_link.document[0].uid === uid
      );
    });
  });

  result = result.filter((f) => f.finalistData !== undefined).filter(Boolean);

  const bookRef = {};
  let filteredResult = [];
  result.forEach((f) => {
    if (awardType === "single") {
      if (
        !f.finalistData ||
        !f.finalistData.book ||
        !f.finalistData.book.document
      )
        return;
      if (bookRef[f.finalistData.book.document[0].id] === undefined) {
        bookRef[f.finalistData.book.document[0].id] = true;
        filteredResult.push(f);
      }
    } else {
      if (!f.finalistData) {
        return;
      }
      filteredResult.push(f);
    }
  });

  result = filteredResult;

  for (let i = result.length - 1; i >= 0; i--) {
    let res = result[i];
    if (
      result.length < 3 &&
      res.finalistData &&
      res.finalistData.book &&
      res.finalistData.book.document &&
      result.filter(
        (f) =>
          f.finalistData &&
          f.finalistData.book &&
          f.finalistData.book.document &&
          f.finalistData.book.document[0].id ===
            res.finalistData.book.document[0].id
      ).length > 1
    ) {
      result.splice(i, 1);
    }
  }

  result.sort(
    firstBy((a, b) => {
      const aname = a.sort_title;
      const bname = b.sort_title;
      if (aname > bname) {
        return 1;
      }
      if (bname > aname) {
        return -1;
      }
      return 0;
    }).thenBy((a, b) => {
      let aResult = a.finalistData && a.finalistData.won === "yes" ? -1 : 0;
      let bResult = b.finalistData && b.finalistData.won === "yes" ? -1 : 0;
      return aResult - bResult;
    })
  );

  const winners = result.filter((item) => item.finalistData.won === "yes");
  if (winners.length >= 3) {
    result.forEach((r) => (r.finalistData.forceAuthor = true));
  }

  return result;
};

const parseJurors = (jurors) => {
  let result = jurors.edges.map(({ node, slug }) => ({
    slug,
    ...node,
  }));
  return result;
};

const parseSelectedWorks = (winner, year, slug) => {
  //console.log(winner, year, slug);
  if (!winner.data.selected_works) {
    // console.log('no selected works');
    return null;
  }
  let works = winner.data.selected_works.filter(
    (work) =>
      resolveLinkById(work.primary.award.raw.id).slug === slug &&
      work.primary.year === year
  );
  if (!works || works.length === 0) {
    // console.log('no selected works found');
    return null;
  }
  return works[0].items;
};

class AwardPage extends React.Component {
  state = { dialogShowing: false, dialogTitle: null, dialogContents: null };

  static getDerivedStateFromProps(props, prevState) {
    if (typeof window === "undefined") {
      return null;
    }
    let loc = props.location.pathname;
    loc = loc.split("/");
    loc.shift();
    loc = loc[0] + "/" + loc[1];
    let routeParser = new Route(`/${loc}(/:path)(/:person)(/)`);
    const { path, person } = routeParser.match(props.location.pathname);
    // console.log('pp',path, person)
    if (!path) {
      // console.log("no path, clearing dialog", path);
      return {
        dialogShowing: false,
        redirect: prevState.redirect || false,
      };
    }

    let state = {
      dialogShowing: true,
      redirect: prevState.redirect || false,
    };

    // console.log('path', path)

    switch (true) {
      case path.indexOf("guidelines") >= 0:
        const {
          guidelines_title,
          guidelines_subtitle,
          guidelines_top_paragraph,
          form_embed,
          source_code,
          pdf_application,
          external_url,
          body1,
        } = props.data.page.data;

        const {
          guidelines_2_title,
          guidelines_2_subtitle,
          guidelines_2_top_paragraph,
          form_embed_2,
          source_code_2,
          pdf_application_2,
          external_url_2,
          body2,
        } = props.data.page.data;

        state.isDialogShowing = true;
        state.dialogContents = (
          <GuidelinesDialogContent
            stateObj={state}
            // {...props.data.page.data}
            guidelinesProps={
              path === `guidelines-short-fiction` || path === `guidelines`
                ? {
                    guidelines_title,
                    guidelines_subtitle,
                    guidelines_top_paragraph,
                    form_embed,
                    source_code,
                    pdf_application,
                    external_url,
                    body1,
                  }
                : {
                    guidelines_title: guidelines_2_title,
                    guidelines_subtitle: guidelines_2_subtitle,
                    guidelines_top_paragraph: guidelines_2_top_paragraph,
                    form_embed: form_embed_2,
                    source_code: source_code_2,
                    pdf_application: pdf_application_2,
                    external_url: external_url_2,
                    body1: body2,
                  }
            }
          />
        );
        break;
      case path === "finalist":
      case path === "winner":
        if (
          props.data.finalists.edges.filter(({ node }) => {
            return node.slug.indexOf(person) >= 0;
          }).length === 0
        ) {
          return { dialogShowing: false, redirect: `/authors/${person}/` };
        }
        getFinalistsDialogContent(
          state,
          `/${loc}/${path}`,
          person,
          parseFinalists(
            props.data.finalists,
            props.data.page.data.year,
            props.data.page.uid,
            props.data.page.data.award_type
          ),
          props.data.page.data.title.text,
          props.data.page.data.year,
          props.data.page.data.award_type
        );
        break;
      default:
        state.dialogShowing = false;
        break;
    }
    return state;
  }

  static getPageMeta(globals, page, location) {
    let title = page.seo_title || page.title;
    let description = page.seo_description || globals.seo_description;
    let img_alt = page.seo_image_alt || globals.seo_image_alt || page.title;

    const galleryImage = getOgImageFromGallery(page?.body);

    let fb_image = page.seo_image
      ? page.seo_image.facebook
      : globals.seo_image.facebook;

    let twitter_image = page.seo_image
      ? page.seo_image.twitter
      : globals.seo_image.twitter;

    if (galleryImage) {
      fb_image = galleryImage;
      img_alt = galleryImage?.alt?.length > 0 ? galleryImage.alt : img_alt;
      twitter_image = galleryImage;
    }

    let pageTitle = `${title.text} | Writers' Trust of Canada`;
    let meta = [
      { property: `og:site_name`, content: pageTitle },
      { name: `title`, content: title.text },
      { property: `og:title`, content: title.text },
      { name: `description`, content: description.text },
      { property: `og:description`, content: description.text },
      { property: `og:image`, content: fb_image.url },
      {
        property: `og:image:width`,
        content: fb_image.dimensions ? fb_image.dimensions.width : null,
      },
      {
        property: `og:image:height`,
        content: fb_image.dimensions ? fb_image.dimensions.height : null,
      },
      { property: `og:image:alt`, content: img_alt.text },
      {
        property: `og:url`,
        content: `https://www.writerstrust.com${location.pathname}`,
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:image`,
        content: twitter_image.url,
      },
    ];
    return meta.filter((m) => m.content);
  }

  renderSlice = (slice, nextSlice) => {
    if (!slice) {
      return null;
    }

    let result = null;
    let Cmp = null;
    let extras = {};
    const slice_type = slice.slice_type;
    switch (true) {
      case sliceTypeMatches(slice_type, "hero"):
        if (
          !slice.items ||
          !slice.items.length > 0 ||
          slice.items[0]?.added !== true
        ) {
          if (!slice.items) {
            slice.items = [];
          }
          let glink;
          let glink2 = null;
          // console.log(this.props.data.page);
          if (
            this.props.data.page.uid.indexOf("bronwen-wallace") >= 0 &&
            this.props.data.page.data.guidelines_2_title &&
            this.props.data.page.data.guidelines_2_title.text
          ) {
            glink = `<a class="${heroStyles.internalLink}" href="/${this.props.data.page.slug}/guidelines-poetry/" title="Prize Guidelines: Poetry">Guidelines: Poetry</a>`;
            glink2 = `<a class="${heroStyles.internalLink}" href="/${this.props.data.page.slug}/guidelines-short-fiction/" title="Prize Guidelines: Short Fiction">Guidelines: Short Fiction</a>`;
          } else {
            glink = `<a class="${heroStyles.internalLink}" href="/${this.props.data.page.slug}/guidelines/" title="Prize Guidelines">Guidelines</a>`;
          }

          let hlink = `<a class="${
            heroStyles.internalLink
          }" href="/writers-books/awards/${
            this.props.data.page.slug.split("/")[1]
          }/all/" title="Prize History">Prize History</a>`;

          slice.items.unshift({
            heading: {
              html: `${glink}<br/>${glink2 ? `${glink2}<br/>` : ``}${hlink}`,
            },
            item_size: `one-fifth`,
            added: true,
          });
        }
        slice.primary.theme = `award`;
        extras.bottomTheme =
          nextSlice && nextSlice.primary && nextSlice.primary.theme
            ? nextSlice.primary.theme
            : null;
        extras.backLink = this.props.data.parentPage;
        Cmp = Hero;
        break;
      case sliceTypeMatches(slice_type, "iframe_embed"):
        Cmp = IFrameEmbed;
        break;
      case sliceTypeMatches(slice_type, "finalists"):
        let finalists = slice.items.filter(
          ({ finalistData }) => finalistData && finalistData.won === "no"
        );
        finalists.sort((a, b) => {
          if (
            a.data.last_name.text.trim().toLowerCase() >
            b.data.last_name.text.trim().toLowerCase()
          ) {
            return 1;
          }
          if (
            a.data.last_name.text.trim().toLowerCase() <
            b.data.last_name.text.trim().toLowerCase()
          ) {
            return -1;
          }
          return 0;
        });

        let winner = slice.items.filter(
          ({ finalistData }) => finalistData && finalistData.won === "yes"
        );
        const hasWinner = winner !== undefined && winner.length > 0;
        if (winner.length >= 3) {
          finalists = slice.items.filter(({ finalistData }) => {
            if (finalistData && finalistData.won === "yes") {
              finalistData.forceAuthor = true;
              return true;
            }
            return false;
          });
          finalists.sort((a, b) => {
            if (
              a.data.last_name.text.trim().toLowerCase() >
              b.data.last_name.text.trim().toLowerCase()
            ) {
              return 1;
            }
            if (
              a.data.last_name.text.trim().toLowerCase() <
              b.data.last_name.text.trim().toLowerCase()
            ) {
              return -1;
            }
            return 0;
          });
        }

        result = (
          <React.Fragment key={slice.id}>
            {hasWinner && winner.length < 3 && (
              <React.Fragment>
                {winner.map((w, idx) => (
                  <Winner
                    key={`${w.data.first_name}-${w.data.last_name}-${idx}`}
                    winner={w}
                    isOnlyWinner={winner.length === 1}
                    slug={this.props.data.page.slug}
                    awardType={this.props.data.page.data.award_type}
                    isSelectionCommittee={
                      this.props.data.page.uid.indexOf("matt-cohen") >= 0
                    }
                  />
                ))}
              </React.Fragment>
            )}
            {this.carousel && (
              <WritersBooksCarousel {...this.carousel} showDate={true} />
            )}
            {finalists.length > 0 && (
              <Finalists
                finalists={finalists}
                theme={this.carousel ? "white" : "grey"}
                slug={this.props.data.page.slug}
                awardType={this.props.data.page.data.award_type}
                forceAuthorView={winner.length >= 3}
                customHeading={winner.length >= 3 ? "Winners" : null}
                showAuthor={
                  this.props.data.page.data.finalist_image === "author"
                }
              />
            )}
            {this.jury &&
              this.body.filter((slice) =>
                sliceTypeMatches(slice.slice_type, "jury")
              )[0].items.length === 0 && (
                <Jury
                  {...this.jury}
                  location={this.props.location}
                  slug={this.props.data.page.slug}
                  dialogPrefix="jury"
                  isSelectionCommittee={
                    this.props.data.page.uid.indexOf("matt-cohen") >= 0
                  }
                />
              )}
          </React.Fragment>
        );
        break;
      case sliceTypeMatches(slice_type, "jury"):
        slice.items.sort((a, b) => {
          if (
            !a ||
            !b ||
            !a.juror ||
            !b.juror ||
            !a.juror.document[0] ||
            !b.juror.document[0]
          ) {
            return 0;
          }
          const aname = a.juror.document[0].sort_title;
          const bname = b.juror.document[0].sort_title;
          if (aname > bname) {
            return 1;
          }
          if (bname > aname) {
            return -1;
          }
          return 0;
        });
        extras.slug = this.props.data.page.slug;
        extras.dialogPrefix = "jury";
        extras.location = this.props.location;
        extras.isSelectionCommittee =
          this.props.data.page.uid.indexOf("matt-cohen") >= 0;
        Cmp = Jury;
        break;
      case sliceTypeMatches(slice_type, "sponsor"):
        Cmp = Sponsor;
        break;
      case sliceTypeMatches(slice_type, "quote"):
        Cmp = Quote;
        break;
      case sliceTypeMatches(slice_type, "big_callout_links"):
        slice.primary = { theme: `grey`, ...slice.primary };
        Cmp = BigCalloutLinks;
        break;
      case sliceTypeMatches(slice_type, "full_width_callouts"):
        slice.primary = { theme: `white`, ...slice.primary };
        Cmp = FullWidthCallouts;
        break;
      case sliceTypeMatches(slice_type, "full_width_callout_list"):
        let items = slice.items;
        if (items.length && !items[0].link) {
          items = slice.items
            .map((item) => {
              return item && item.callout && item.callout.document
                ? item.callout.document[0].data
                : null;
            })
            .filter(Boolean);
        }
        slice.items = items;
        slice.primary = { ...slice.primary };
        Cmp = FullWidthCallouts;
        break;
      case sliceTypeMatches(slice_type, "writers___books_carousel"):
        Cmp = WritersBooksCarousel;
        extras.showDate = true;
        break;
      case sliceTypeMatches(slice_type, "image_gallery"):
        Cmp = Gallery;
        break;
      case sliceTypeMatches(slice_type, "about_award_block"):
        extras.theme = "white";
        Cmp = AboutAwardBlock;
        break;
      case sliceTypeMatches(slice_type, "finalist_long_list"):
        Cmp = FinalistLongList;
        break;
      case sliceTypeMatches(slice_type, "quote_gallery"):
        Cmp = QuoteGallery;
        break;
      case sliceTypeMatches(slice_type, "content_columns"):
        Cmp = ContentColumns;
        break;
      case sliceTypeMatches(slice_type, "photo_gallery_2024"):
        Cmp = Gallery2024;
        break;
      default:
        return null;
    }
    if (!Cmp && !result) {
      return null;
    }
    if (!Cmp && result) {
      return result;
    }
    return (
      <Cmp
        {...slice}
        key={slice.id}
        pageId={this.props.data.page.id}
        slug={this.props.data.page.slug}
        awardType={this.props.data.page.data.award_type}
        {...extras}
      />
    );
  };

  componentDidMount() {
    this.canRedirect = true;
    suppressDialog = false;
    this.positionDialog();
    this.checkQueryString();
    if (this.state.redirect && this.canRedirect) {
      this.canRedirect = false;
      let loc = this.state.redirect;
      setTimeout(() => {
        navigate(loc, { state: { redirect: true } });
        this.setState({ redirect: false });
      }, 1);
      return;
    }
    setTimeout(() => {
      // window.scrollTo(0, 0);
      GlobalEmitter.on(events.resize, this.onResize);
      this.positionDialog();
      this.checkDialog();
    }, 0);
  }

  componentWillUnmount() {
    this.dialogDisabled = true;
    GlobalEmitter.off(events.resize, this.onResize);
  }

  onResize = () => {
    this.positionDialog();
  };

  positionDialog() {
    // if (this.state.dialogContents) {
    //   let dialog = this.dialog ? this.dialog.dialog : null;
    //   if (!dialog) {
    //     return;
    //   }
    //   clearTimeout(this.dialogTimeout);
    //   this.dialogTimeout = setTimeout(() => {
    //     let width = dialog.getBoundingClientRect().width;
    //     let height = dialog.getBoundingClientRect().height;
    //     dialog.style.top = `${Math.round(
    //       window.innerHeight * 0.5 - height * 0.5
    //     )}px`;
    //     dialog.style.left = `${Math.round(
    //       window.innerWidth * 0.5 - width * 0.5
    //     )}px`;
    //     let container = document
    //       .getElementById("award-page-dialog")
    //       .querySelector(".dialog-content .container");
    //     if (window.location.hash !== `#form-embed`) container.scrollTop = 0;
    //   }, 0);
    // }
  }

  hideDialog = (e) => {
    document.documentElement.style.overflow = "auto";
    if (this.dialog) {
      try {
        this.dialog.dialog.parentNode
          .querySelector(".dialog-overlay")
          .removeEventListener("click", this.hideDialog);
        this.dialog.off("hide", this.hideDialog);
      } catch (e) {}
    }
    this.isDialogShowing = false;
    if (this.dialogDisabled || suppressDialog) {
      return;
    }
    let loc = this.props.location.pathname;
    loc = loc.split("/");
    loc.shift();
    loc = loc[0] + "/" + loc[1];
    navigate(`/${loc}`, { state: { modal: true } });
  };

  onShowDialog = () => {
    document.documentElement.style.overflow = "hidden";
  };

  checkDialog = () => {
    if (suppressDialog) return;
    if (this.dialog) {
      this.dialog.on("show", this.onShowDialog);
    }
    if (this.dialog && this.state.dialogShowing) {
      this.dialog.dialog.parentNode
        .querySelector(".dialog-overlay")
        .addEventListener("click", this.hideDialog);
      this.dialog.on("hide", this.hideDialog);
      this.isDialogShowing = true;
      clearTimeout(this.dialogTimeout);
      this.dialogTimeout = setTimeout(() => {
        this.dialog.show();
        this.positionDialog();
      }, 1);
    } else if (
      this.dialog &&
      !this.state.dialogShowing &&
      this.isDialogShowing
    ) {
      // console.log("hiding dialog");
      this.dialog.off("hide", this.hideDialog);
      this.isDialogShowing = false;
      this.dialog.hide();
    }
  };

  checkQueryString() {
    if (!this.props.location) {
      return;
    }
    const qs = querystring.parse(this.props.location.search.substr(1));
    if (qs && (qs.hasOwnProperty("winner") || qs.hasOwnProperty("finalist"))) {
      let key = Object.keys(qs)[0];
      const notInList =
        this.props.data.finalists.edges.filter(
          ({ node }) => node.slug.indexOf(qs[key]) >= 0
        ).length === 0;
      if (notInList) {
        this.setState({
          dialogShowing: false,
          redirect: `/authors/${qs[key]}/`,
        });
        return;
      }
      setTimeout(() => {
        navigate(`/${this.props.data.page.slug}/${key}/${qs[key]}/`, {
          state: { modal: true },
        });
      }, 1000);
    } else if (qs && qs.hasOwnProperty("guidelines-short-fiction")) {
      setTimeout(() => {
        navigate(`/${this.props.data.page.slug}/guidelines-short-fiction/`, {
          state: { modal: true },
        });
      }, 1000);
    } else if (qs && qs.hasOwnProperty("guidelines-poetry")) {
      setTimeout(() => {
        navigate(`/${this.props.data.page.slug}/guidelines-poetry/`, {
          state: { modal: true },
        });
      }, 1000);
    } else if (qs && qs.hasOwnProperty("guidelines")) {
      setTimeout(() => {
        navigate(`/${this.props.data.page.slug}/guidelines/`, {
          state: { modal: true },
        });
      }, 1000);
    }
  }

  componentDidUpdate(props) {
    // console.log(
    //   "updated",
    //   "redirect",
    //   this.state.redirect,
    //   "can",
    //   this.canRedirect
    // );
    if (this.state.redirect && this.canRedirect) {
      this.canRedirect = false;
      let loc = this.state.redirect;
      setTimeout(() => {
        navigate(loc, { state: { redirect: true } });
        this.setState({ redirect: false });
      }, 1);
      return;
    }
    this.checkDialog();
  }

  constructor(props) {
    super(props);
    this.canRedirect = true;
    //this.meta= getPageMeta(props.data.globals.data, props.data.page.data)
    let { page, finalists, jurors } = props.data;
    const { year, show_finalists, award_type } = page.data;
    const finalistsOrdering =
      page?.data?.finalists_ordering?.length > 0
        ? page?.data?.finalists_ordering
        : false;
    this.finalistsAutoSort = finalistsOrdering !== false ? false : true;
    let finalistsPosition = 1;
    this.body = Array.from(page.data.body);

    if (show_finalists === "yes" && finalists) {
      // find finalists_placement slice index
      for (let i = this.body.length - 1; i >= 0; i--) {
        if (sliceTypeMatches(this.body[i].slice_type, "finalists_placement")) {
          finalistsPosition = i;
          this.body.splice(i, 1);
        }
      }
      //
      this.finalists = parseFinalists(
        finalists,
        year,
        props.data.page.uid,
        props.data.page.data.award_type
      );

      if (finalistsOrdering) {
        const sortingArr = finalistsOrdering
          .split(",")
          .map((str) => str.trim().toLowerCase());
        this.finalists.sort(function (a, b) {
          const sort_title_a = a.sort_title.split(" ")[0];
          const sort_title_b = b.sort_title.split(" ")[0];
          return (
            sortingArr.indexOf(sort_title_a) - sortingArr.indexOf(sort_title_b)
          );
        });
      }

      let winner = this.finalists.filter(
        ({ finalistData }) => finalistData && finalistData.won === "yes"
      );
      this.carousel = null;
      if (winner && winner[0]) {
        this.selectedWorks = parseSelectedWorks(
          winner[0],
          year,
          props.data.page.slug
        );
      }

      if (
        winner &&
        winner[0] &&
        award_type === "lifetime" &&
        this.selectedWorks &&
        this.selectedWorks.length
      ) {
        this.carousel = {
          id: `carousel=${uid()}`,
          slice_type: "writers___books_carousel",
          primary: { heading: { text: "Selected Works" }, theme: `grey` },
          showDate: true,
          items: this.selectedWorks.map((work) => {
            // console.log(work.book);
            return {
              item: work.book,
            };
          }),
        };
      }
      this.jury = null;
      if (
        this.body.filter((slice) => sliceTypeMatches(slice.slice_type, "jury"))
          .length > 0 &&
        jurors &&
        jurors.edges.length > 0
      ) {
        this.jurors = parseJurors(jurors, year);
        this.jury = {
          id: `jury=${uid()}`,
          slice_type: "jury",
          isSelectionCommittee:
            this.props.data.page.uid.indexOf("matt-cohen") >= 0,
          primary: {
            heading: {
              text: award_type === "lifetime" ? "Selection Committee" : "Jury",
            },
            theme: `black`,
          },
          items: this.jurors.map((j) => ({
            juror: { document: [{ slug: j.slug, data: j.data }] },
          })),
        };
      }

      this.body.splice(finalistsPosition, 0, {
        id: `finalists=${uid()}`,
        slice_type: "finalists",
        items: this.finalists,
        isSelectionCommittee:
          this.props.data.page.uid.indexOf("matt-cohen") >= 0,
      });
      // this.body = [
      //   {
      //     id: `finalists=${uid()}`,
      //     slice_type: 'finalists',
      //     items: this.finalists,
      //     isSelectionCommittee:
      //       this.props.data.page.uid.indexOf('matt-cohen') >= 0
      //   },
      //   ...this.body
      // ];
    }
  }

  shouldComponentUpdate(props) {
    if (props.location.pathname.indexOf(props.data.page.slug) === -1) {
      return false;
    }
    return true;
  }

  render() {
    let { page } = this.props.data;
    let { footerData, globals } = this.props.pageContext;
    const { body } = this;
    const { title } = page.data;
    const { dialogTitle, dialogContents } = this.state;

    return (
      <React.Fragment>
        <Helmet
          bodyAttributes={{ class: "award-page" }}
          title={`${title.text} | Writers' Trust of Canada`}
          meta={AwardPage.getPageMeta(
            globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        {body &&
          body.length > 0 &&
          body.map((slice, idx) => {
            return this.renderSlice(slice, body[idx + 1]);
          })}
        <Footer data={footerData} theme={page.data.footer_theme} />
        <Dialog
          id="award-page-dialog"
          appRoot="#__gatsby"
          dialogRoot="#dialog-root"
          dialogRef={(dialog) => (this.dialog = dialog)}
          title={dialogTitle || ``}
          closeButtonContent={
            <i className="icon">
              <FontAwesomeIcon icon={faTimes} />
            </i>
          }
          classNames={{
            element: "dialog-content",
            overlay: "dialog-overlay",
            base: "dialog",
            closeButton: "dialog-close",
            title: "is-sr-only",
          }}
        >
          {dialogContents || ``}
        </Dialog>
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query ($uid: String, $year: Date) {
    parentPage: prismicLandingPage(uid: { eq: "awards" }) {
      slug
      data {
        title {
          text
        }
      }
    }
    page: prismicAwardPage(uid: { eq: $uid }) {
      id
      uid
      slug
      data {
        snippets {
          snippet {
            text
          }
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        seo_image {
          twitter {
            url
            dimensions {
              width
              height
            }
          }
          facebook {
            url
            dimensions {
              width
              height
            }
          }
        }
        seo {
          ... on PrismicAwardPageSeoMeta {
            primary {
              name {
                text
              }
              property {
                text
              }
              content {
                text
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
        award_type
        footer_theme
        show_finalists
        finalists_ordering
        finalist_image
        year
        title {
          text
        }
        source_code {
          text
        }
        body {
          ... on PrismicAwardPageBodyHero {
            id
            slice_type
            primary {
              category {
                text
              }
              heading {
                text
              }
              button_label {
                text
              }
              button_link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              bottom_callout_heading {
                text
              }
              bottom_callout_paragraph {
                html
                text
              }
              bottom_callout_button_label {
                text
              }
              bottom_callout_button_link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              show_side_panel
              theme
              enable_footer
              enable_floating_side_panel
              show_footer_item_borders
              footer_heading {
                text
              }
            }
            items {
              item_size
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              heading {
                text
                html
              }
              link {
                url
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
            }
          }
          ... on PrismicAwardPageBodyFinalistsPlacement {
            id
            slice_type
          }
          ... on PrismicAwardPageBodySponsor {
            id
            slice_type
            primary {
              sponsor {
                document {
                  data {
                    name {
                      text
                    }
                    logo {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                    tagline {
                      html
                    }
                    description {
                      html
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAwardPageBodyQuote {
            id
            slice_type
            primary {
              theme
              content {
                text
              }
              person {
                text
              }
              caption {
                text
              }
              callout_label {
                text
              }
              callout_link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
            }
          }
          ... on PrismicAwardPageBodyJury {
            id
            slice_type
            primary {
              description {
                text
                html
              }
            }
            items {
              juror {
                document {
                  ... on PrismicWriter {
                    slug
                    sort_title
                    data {
                      first_name {
                        text
                      }
                      last_name {
                        text
                      }
                      image {
                        url
                        dimensions {
                          width
                          height
                        }
                        carousel {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                      }
                      bio {
                        text
                        html
                      }
                    }
                  }
                  ... on PrismicStaffMember {
                    slug: uid
                    sort_title
                    data {
                      first_name {
                        text
                      }
                      last_name {
                        text
                      }
                      bio {
                        text
                        html
                      }
                      image {
                        url
                        dimensions {
                          width
                          height
                        }
                        carousel {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAwardPageBodyBigCalloutLinks {
            id
            slice_type
            primary {
              theme
            }
            items {
              link_label {
                text
              }
              link {
                url
                raw {
                  link_type
                  target
                  slug
                  uid
                  id
                }
              }
              modal_link
            }
          }
          ... on PrismicAwardPageBodyFullWidthCalloutList {
            id
            slice_type
            primary {
              theme
            }
            items {
              callout {
                document {
                  data {
                    link {
                      raw {
                        link_type
                        slug
                        uid
                        id
                      }
                    }
                    image {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                    category_heading {
                      text
                    }
                    heading {
                      text
                    }
                    label {
                      text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAwardPageBodyWritersBooksCarousel {
            id
            slice_type
            primary {
              heading {
                html
                text
              }
              button_label {
                html
                text
              }
            }
            items {
              item {
                document {
                  type: __typename
                  ... on PrismicBook {
                    id
                    uid
                    authorSlug
                    slug
                    data {
                      publish_date
                      short_story
                      title {
                        text
                      }
                      description {
                        text
                        html
                      }
                      image: cover_image {
                        url
                        dimensions {
                          width
                          height
                        }
                        carousel {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicWriter {
                    id
                    uid
                    slug
                    data {
                      first_name {
                        text
                      }
                      last_name {
                        text
                      }
                      image {
                        url
                        dimensions {
                          width
                          height
                        }
                        carousel {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                      }
                      description: bio {
                        text
                        html
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAwardPageBodyImageGallery {
            id
            slice_type
            primary {
              theme
              heading {
                text
              }
              facebook_gallery_id {
                text
              }
            }
            items {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              caption {
                html
              }
            }
          }
          ... on PrismicAwardPageBodyQuoteGallery {
            id
            slice_type
            primary {
              theme
            }
            items {
              content {
                text
                html
              }
              person {
                text
              }
              caption {
                text
              }
            }
          }
          ... on PrismicAwardPageBodyIframeEmbed {
            id
            slice_type
            primary {
              theme
              width
              height
              responsive
              heading {
                text
              }
              embed_url
              embed_code {
                text
              }
            }
          }
          ... on PrismicAwardPageBodyContentColumns {
            id
            slice_type
            primary {
              theme
              mobile_columns
              tablet_columns
              desktop_columns
              heading {
                text
              }
            }
            items {
              content {
                html
              }
            }
          }
          ... on PrismicAwardPageBodyFullWidthCalloutList {
            id
            slice_type
            primary {
              theme
            }
            items {
              callout {
                document {
                  data {
                    link {
                      raw {
                        link_type
                        slug
                        uid
                        id
                      }
                    }
                    image {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                    category_heading {
                      text
                    }
                    heading {
                      text
                    }
                    label {
                      text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicAwardPageBodyAboutAwardBlock {
            id
            slice_type
            primary {
              layout
              category {
                text
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              heading {
                text
                html
              }
              content {
                html
              }
            }
          }
          ... on PrismicAwardPageBodyPhotoGallery2024 {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              image {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
        pdf_application {
          url
        }
        external_url {
          text
        }
        guidelines_title {
          text
        }
        guidelines_subtitle {
          text
        }
        guidelines_top_paragraph {
          html
        }
        form_embed {
          embed_url
          url
          provider_name
        }
        body1 {
          ... on PrismicAwardPageBody1ContentBlock {
            id
            slice_type
            primary {
              columns
              heading {
                html
              }
            }
            items {
              content {
                html
              }
            }
          }
        }
        external_url_2 {
          text
        }
        guidelines_2_title {
          text
        }
        guidelines_2_subtitle {
          text
        }
        guidelines_2_top_paragraph {
          html
        }
        body2 {
          ... on PrismicAwardPageBody2ContentBlock {
            id
            slice_type
            primary {
              columns
              heading {
                html
              }
            }
            items {
              content {
                html
              }
            }
          }
        }
      }
    }
    finalists: allPrismicWriter(
      filter: {
        data: {
          award_finalist: {
            elemMatch: {
              award_link: { raw: { uid: { eq: $uid } } }
              year: { eq: $year }
            }
          }
        }
      }
    ) {
      edges {
        node {
          uid
          slug
          sort_title
          data {
            image {
              url
              dimensions {
                width
                height
              }
            }
            alternate_image {
              url
              dimensions {
                width
                height
              }
            }
            selected_works {
              primary {
                award {
                  raw {
                    id
                    uid
                    slug
                  }
                }
                year
              }
              items {
                book {
                  document {
                    slug
                    authorSlug
                    data {
                      title {
                        text
                      }
                      short_story
                      publish_date
                      image: cover_image {
                        url
                        dimensions {
                          width
                          height
                        }
                        carousel {
                          url
                          dimensions {
                            width
                            height
                          }
                        }
                      }
                      publisher {
                        document {
                          data {
                            name {
                              text
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            first_name {
              text
            }
            last_name {
              text
            }
            image {
              url
              dimensions {
                width
                height
              }
              carousel {
                url
                dimensions {
                  width
                  height
                }
              }
            }
            alternate_image {
              url
            }
            bio {
              text
              html
            }
            award_finalist {
              year
              won
              award_subcategory {
                text
              }
              award_link {
                document {
                  uid
                }
              }
              finalist_blurb {
                text
                html
              }
              citation {
                text
                html
              }
              citation_excerpt {
                text
                html
              }
              citation_source {
                text
              }
              youtube_embed {
                provider_name
                version
                embed_url
                width
                height
              }
              book {
                document {
                  id
                  data {
                    title {
                      text
                    }
                    short_story
                    description {
                      text
                      html
                    }
                    apple_books_link {
                      url
                    }
                    pdf_link {
                      url
                    }
                    soundcloud_link {
                      url
                    }
                    cover_image {
                      url
                      dimensions {
                        width
                        height
                      }
                      carousel {
                        url
                        dimensions {
                          width
                          height
                        }
                      }
                    }
                    publisher {
                      document {
                        id
                        data {
                          name {
                            text
                          }
                        }
                      }
                    }
                    author {
                      document {
                        uid
                        slug
                        data {
                          first_name {
                            text
                          }
                          last_name {
                            text
                          }
                          bio {
                            html
                          }
                          image {
                            url
                            dimensions {
                              width
                              height
                            }
                            carousel {
                              url
                              dimensions {
                                width
                                height
                              }
                            }
                          }
                        }
                      }
                    }
                    authors {
                      author1 {
                        document {
                          uid
                          slug
                          data {
                            first_name {
                              text
                            }
                            last_name {
                              text
                            }
                            bio {
                              html
                            }
                            image {
                              url
                              dimensions {
                                width
                                height
                              }
                              carousel {
                                url
                                dimensions {
                                  width
                                  height
                                }
                              }
                            }
                          }
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    jurors: allPrismicWriter(
      filter: {
        data: {
          jury: {
            elemMatch: {
              award: { raw: { uid: { eq: $uid } } }
              year: { eq: $year }
            }
          }
        }
      }
      sort: { fields: sort_title, order: ASC }
    ) {
      edges {
        node {
          slug
          data {
            first_name {
              text
            }
            last_name {
              text
            }
            bio {
              text
              html
            }
            image {
              url
              dimensions {
                width
                height
              }
              carousel {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AwardPage;
